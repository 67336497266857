import { Component, OnInit } from '@angular/core';
import { IngredientService } from 'src/app/services/ingredient/ingredient.service';

@Component({
  selector: 'app-ingredientlist',
  templateUrl: './ingredientlist.component.html',
  styleUrls: ['./ingredientlist.component.scss']
})
export class IngredientlistComponent implements OnInit {

  constructor(
    private ingredientService: IngredientService,
  ) { }

  ingredients;

  ngOnInit() {
    this.getIngredients();
  }

  getIngredients() {
    this.ingredientService.getIngredients().pipe().subscribe(r => {
      this.ingredients = r;
    });
  }

}
