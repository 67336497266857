export class Ingredient {
    id: number;
    name: string;
    basemassunitid: number;
    basevolumeunitid: number;
    basemassvalue: number;
    basevolumevalue: number;
    cost: number;
    calories: number;
    brand: string;
}
