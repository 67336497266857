import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserService } from '../../services/user/user.service';
import { User } from 'src/app/models/user/user';

@Injectable({
  providedIn: 'root'
})
export class StepService {

  constructor(
    private HttpClient: HttpClient,
    private UserService: UserService
  ) { }

  user: User = this.UserService.getStoredUser();

  createRecipeStep(step, recipe): Observable<Object> {
    var data = {
      recipe: recipe,
      step: step,
      user: this.user
    };
    return this.HttpClient.post('//167.99.54.166:7171/step', data);
  }

  updateRecipeStep(step): Observable<Object> {
    return this.HttpClient.put('//167.99.54.166:7171/step', step);
  }

  updateRecipeSteps(steps): Observable<Object> {
    return this.HttpClient.put('//167.99.54.166:7171/steps', steps);
  }
}
