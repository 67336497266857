import { Component, OnInit } from '@angular/core';
import { RecipeService } from 'src/app/services/recipe/recipe.service';
import { ActivatedRoute } from '@angular/router';
import { StepService } from 'src/app/services/step/step.service';
import { RecipeStep } from 'src/app/models/recipestep/recipestep';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { IngredientService } from 'src/app/services/ingredient/ingredient.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { IngredientmodalComponent } from '../ingredientmodal/ingredientmodal.component';

@Component({
  selector: 'app-editrecipe',
  templateUrl: './editrecipe.component.html',
  styleUrls: ['./editrecipe.component.scss']
})
export class EditrecipeComponent implements OnInit {

  constructor(
    private recipeService: RecipeService,
    private stepService: StepService,
    private route: ActivatedRoute,
    private ingredientService: IngredientService,
    private modalService: BsModalService
  ) { }

  isWrittingNewStep: boolean = false;
  isReorderedSteps: boolean = false;
  id: number;
  recipe;
  recipeSteps: RecipeStep[] = [];
  newStep: RecipeStep = new RecipeStep();
  ingredientList: Object[] = [];
  ingredientSearch: string = "";
  ingredientSearchList;
  bsModalRef: BsModalRef;
  noSearchIngredients: boolean = false;

  ngOnInit() {
    this.route.params.subscribe(p => {
      this.id = p.id;
      this.getFullRecipe();
    });
  }

  getFullRecipe() {
    this.recipeService.getFullRecipe(this.id).pipe().subscribe(r => {
      this.recipe = r.recipe;
      this.recipeSteps = r.steps;
      this.ingredientList = r.ingredients;
      this.isReorderedSteps = false;
      this.isWrittingNewStep = false;
      this.sortRecipeSteps();
    });
  }

  addNewStep() {
    this.newStep.stepnumber = this.recipeSteps.length + 1;
    this.stepService.createRecipeStep(this.newStep, this.recipe).pipe().subscribe(r => {
      this.newStep.description = '';
      this.getFullRecipe();
    });
  }

  editStep(step) {
    step.edit = true;
    step.editedStep = {
      id: step.id,
      description: step.description,
      stepnumber: step.stepnumber
    }
  }

  updateStep(step) {
    this.stepService.updateRecipeStep(step.editedStep).pipe().subscribe(r => {
      this.getFullRecipe();
    });
  }

  saveStepChanges() {
    this.stepService.updateRecipeSteps(this.recipeSteps).pipe().subscribe(r => {
      this.getFullRecipe();
    });
  }

  drop(event: CdkDragDrop<string[]>) {
    this.isReorderedSteps = true;
    moveItemInArray(this.recipeSteps, event.previousIndex, event.currentIndex);
    this.recipeSteps.forEach((element, index) => {
      element.stepnumber = index + 1;
    });
  }
  
  sortRecipeSteps() {
    this.recipeSteps = this.recipeSteps.sort((a, b) => a.stepnumber > b.stepnumber ? 1 : a.stepnumber === b.stepnumber ? 0 : -1);
  }

  searchIngredientList() {
    this.ingredientService.searchIngredients(this.ingredientSearch).pipe().subscribe(r => {
      if (r.length > 0) {
        this.ingredientSearchList = r;
        this.noSearchIngredients = false;
      }
      else {
        this.noSearchIngredients = true;
      }
    });
  }

  addToIngredientList(ingredient) {
    console.log(ingredient);
    const initialState = {
      returnFunc: this.ingredientModalReturnFunc.bind(this),
      ingredient: ingredient
    };
    this.bsModalRef = this.modalService.show(IngredientmodalComponent, {initialState});
  }

  ingredientModalReturnFunc(data) {
    this.ingredientSearchList = [];
    this.ingredientSearch = '';
    this.recipeService.addRecipeIngredient(this.recipe, data['recipeingredient']).pipe().subscribe(r => {
      this.getFullRecipe();
    });
  }

  deleteRecipeIngredient(ri) {
    if (confirm('Are you sure you want to remove this ingredient?')){
      this.recipeService.deleteRecipeIngredient(ri).pipe().subscribe(r => {
        this.getFullRecipe();
      });
    }
  }

}
