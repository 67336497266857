import { Component, OnInit } from '@angular/core';
import { IngredientService } from 'src/app/services/ingredient/ingredient.service';
import { ActivatedRoute } from '@angular/router';
import { MeasurementUnitService } from 'src/app/services/measurementunit/measurement-unit.service';

@Component({
  selector: 'app-editingredient',
  templateUrl: './editingredient.component.html',
  styleUrls: ['./editingredient.component.scss']
})
export class EditingredientComponent implements OnInit {

  constructor(
    private ingredientService: IngredientService,
    private router: ActivatedRoute,
    private measurementUnitService: MeasurementUnitService
  ) { }

  ingredient;
  volumeunits: Object[] = [];
  massunits: Object[] = [];

  ngOnInit() {
    this.getIngredient();
    this.getMeasurementUnits();
  }

  getMeasurementUnits() {
    this.measurementUnitService.getUnits().pipe().subscribe(r => {
      r.forEach(unit => {
        if (unit['measurementtype'] == 'volume') {
          this.volumeunits.push(unit);
        }
        else if (unit['measurementtype'] == 'mass') {
          this.massunits.push(unit);
        }
      });
    });
  }

  getIngredient() {
    this.router.params.subscribe(p => {
      this.ingredientService.getIngredient(p.id).pipe().subscribe(i => {
        this.ingredient = i;
        console.log(this.ingredient);
      });
    });
  }

}
