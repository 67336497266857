import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/user/user.service';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(
    private userService: UserService,
    private cookieService: CookieService,
    private router: Router
  ) { }

  isCreateNewUser: Boolean = false;
  loginMessage: String;
  user: Object;

  credentials: Object = new Object({
    username: null,
    password: null
  });

  newuser: Object = new Object({
    username: null,
    password: null,
    confirmpassword: null,
    email: null,
    firstname: null,
    lastname: null
  });

  ngOnInit() {
  }

  login() {
    this.userService.login(this.credentials).subscribe(r => {
      this.user = r;
      this.cookieService.set('user', JSON.stringify(this.user), .25);
      this.router.navigate(['']);
    })
  }

  createUser() {
    this.userService.createuser(this.newuser).subscribe(r => {
      this.loginMessage = r['message'];
      this.isCreateNewUser = false;
    })
  }
npm
}
