import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MatTableModule } from '@angular/material';
import { CdkTableModule } from '@angular/cdk/table';
import { MatPaginatorModule } from '@angular/material';
import { MatFormFieldModule } from '@angular/material';
import { MatInputModule } from '@angular/material';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ModalModule } from 'ngx-bootstrap/modal';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { LandingComponent } from './components/landing/landing.component';
import { RecipelistComponent } from './components/recipes/recipelist/recipelist.component';
import { RecipeComponent } from './components/recipes/recipe/recipe.component';
import { RecipestepComponent } from './components/recipes/recipestep/recipestep.component';
import { IngredientstocklistComponent } from './components/ingredients/ingredientstocklist/ingredientstocklist.component';
import { IngredientComponent } from './components/ingredients/ingredient/ingredient.component';
import { GrocerylistComponent } from './components/grocerylist/grocerylist.component';
import { UserComponent } from './components/user/user.component';
import { SideNavComponent } from './components/side-nav/side-nav.component';
import { NewrecipeComponent } from './components/recipes/newrecipe/newrecipe.component';
import { EditrecipeComponent } from './components/recipes/editrecipe/editrecipe.component';
import { IngredientlistComponent } from './components/ingredients/ingredientlist/ingredientlist.component';
import { NewingredientComponent } from './components/ingredients/newingredient/newingredient.component';
import { EditingredientComponent } from './components/ingredients/editingredient/editingredient.component';
import { IngredientmodalComponent } from './components/recipes/ingredientmodal/ingredientmodal.component';
import { FilterPipe } from './pipes/filter.pipe';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LandingComponent,
    RecipelistComponent,
    RecipeComponent,
    RecipestepComponent,
    IngredientstocklistComponent,
    IngredientComponent,
    GrocerylistComponent,
    UserComponent,
    SideNavComponent,
    NewrecipeComponent,
    EditrecipeComponent,
    IngredientlistComponent,
    NewingredientComponent,
    EditingredientComponent,
    IngredientmodalComponent,
    FilterPipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    MatTableModule,
    CdkTableModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatInputModule,
    DragDropModule,
    ModalModule.forRoot()
  ],
  exports: [
    IngredientmodalComponent
  ],
  entryComponents: [
    IngredientmodalComponent
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
