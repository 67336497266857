import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserService } from '../../services/user/user.service';
import { User } from 'src/app/models/user/user';
import { FullRecipe } from 'src/app/models/fullrecipe/full-recipe';

@Injectable({
  providedIn: 'root'
})
export class RecipeService {

  constructor(
    private HttpClient: HttpClient,
    private UserService: UserService
  ) { }

  user: User = this.UserService.getStoredUser();

  getRecipes(): Observable<Object> {
    return this.HttpClient.get('//167.99.54.166:7171/recipes?userid=' + this.user.id);
  }

  getRecipe(id): Observable<Object> {
    return this.HttpClient.get('//167.99.54.166:7171/recipe?userid=' + this.user.id + '&recipeid=' + id);
  }

  getFullRecipe(id): Observable<FullRecipe> {
    return this.HttpClient.get<FullRecipe>('//167.99.54.166:7171/fullrecipe?userid=' + this.user.id + '&recipeid=' + id);
  }

  createRecipe(recipe): Observable<Object> {
    var data = {
      recipe: recipe,
      user: this.user
    };
    return this.HttpClient.post('//167.99.54.166:7171/recipe', data);
  }

  addRecipeIngredient(recipe, ingredient): Observable<Object> {
    var data = {
      recipe: recipe,
      ingredient: ingredient
    };
    return this.HttpClient.post('//167.99.54.166:7171/recipeingredient', data);
  }

  deleteRecipeIngredient(recipeingredient): Observable<Object> {
    return this.HttpClient.delete('//167.99.54.166:7171/recipeingredient?id=' + recipeingredient.id);
  }
}