import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserService } from '../user/user.service';
import { Observable } from 'rxjs';
import { User } from 'src/app/models/user/user';

@Injectable({
  providedIn: 'root'
})
export class MeasurementUnitService {

  constructor(
    private HttpClient: HttpClient,
    private UserService: UserService
  ) { }
  
  user: User = this.UserService.getStoredUser();

  getUnits(): Observable<Object[]> {
    return this.HttpClient.get<Object[]>('//167.99.54.166:7171/units');
  }
}
