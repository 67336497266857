import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { User } from 'src/app/models/user/user';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private HttpClient: HttpClient,
    private cookieService: CookieService
    ) { }

  login(credentials): Observable<Object> {
    return this.HttpClient.post('//167.99.54.166:7171/login', credentials);
  }

  createuser(user): Observable<Object> {
    return this.HttpClient.post('//167.99.54.166:7171/user', user);
  }

  getStoredUser(): User {
    var user = JSON.parse(this.cookieService.get('user'));
    return user;
  }
}
