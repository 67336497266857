import { Component, OnInit } from '@angular/core';
import { IngredientService } from 'src/app/services/ingredient/ingredient.service';
import { Ingredient } from 'src/app/models/ingredient/ingredient';
import { MeasurementUnitService } from 'src/app/services/measurementunit/measurement-unit.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-newingredient',
  templateUrl: './newingredient.component.html',
  styleUrls: ['./newingredient.component.scss']
})
export class NewingredientComponent implements OnInit {

  constructor(
    private ingredientService: IngredientService,
    private measurementUnitService: MeasurementUnitService,
    private router: Router
  ) { }

  newIngredient: Ingredient = new Ingredient();
  volumeunits: Object[] = [];
  massunits: Object[] = [];

  ngOnInit() {
    this.getMeasurementUnits();
  }

  getMeasurementUnits() {
    this.measurementUnitService.getUnits().pipe().subscribe(r => {
      r.forEach(unit => {
        if (unit['measurementtype'] == 'volume') {
          this.volumeunits.push(unit);
        }
        else if (unit['measurementtype'] == 'mass') {
          this.massunits.push(unit);
        }
      });
    });
  }

  saveIngredient() {
    this.ingredientService.createIngredient(this.newIngredient).pipe().subscribe(r => {
      this.router.navigate(['/ingredient/' + r['ingredientID']]);
    });
  }

}
