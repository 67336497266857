import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RecipeService } from 'src/app/services/recipe/recipe.service';
import { Recipe } from 'src/app/models/recipe/recipe';
import { RecipeStep } from 'src/app/models/recipestep/recipestep';

@Component({
  selector: 'app-recipe',
  templateUrl: './recipe.component.html',
  styleUrls: ['./recipe.component.scss']
})
export class RecipeComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private recipeService: RecipeService
  ) { }

  id: number;
  recipe: Recipe;
  recipeSteps: RecipeStep[];
  ingredientList: Object[] = [];

  ngOnInit() {
    this.route.params.subscribe(p => {
      this.id = p.id;
      this.getFullRecipe();
    });
  }

  getFullRecipe() {
    this.recipeService.getFullRecipe(this.id).pipe().subscribe(r => {
      this.recipe = r.recipe;
      this.recipeSteps = r.steps;
      this.ingredientList = r.ingredients;
      this.sortRecipeSteps();
    });
  }
  
  sortRecipeSteps() {
    this.recipeSteps = this.recipeSteps.sort((a, b) => a.stepnumber > b.stepnumber ? 1 : a.stepnumber === b.stepnumber ? 0 : -1);
  }

}
