import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserService } from '../../services/user/user.service';
import { User } from 'src/app/models/user/user';
import { Ingredient } from 'src/app/models/ingredient/ingredient';

@Injectable({
  providedIn: 'root'
})
export class IngredientService {

  constructor(
    private HttpClient: HttpClient,
    private UserService: UserService
  ) { }

  user: User = this.UserService.getStoredUser();

  getIngredient(id): Observable<Object> {
    return this.HttpClient.get('//167.99.54.166:7171/ingredient?userid=' + this.user.id + '&id=' + id);
  }

  getIngredients(): Observable<Object> {
    return this.HttpClient.get('//167.99.54.166:7171/ingredients?userid=' + this.user.id);
  }
  
  createIngredient(ingredient): Observable<Object> {
    var data = {
      ingredient: ingredient,
      user: this.user
    };
    return this.HttpClient.post('//167.99.54.166:7171/ingredient', data);
  }

  updateIngredient(ingredient): Observable<Ingredient> {
    return this.HttpClient.put<Ingredient>('//167.99.54.166:7171/ingredient', ingredient);
  }

  searchIngredients(searchterm): Observable<Ingredient[]> {
    var data = {
      searchterm: searchterm
    };
    return this.HttpClient.post<Ingredient[]>('//167.99.54.166:7171/searchingredients', data)
  }
}
