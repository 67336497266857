import { Component, OnInit } from '@angular/core';
import { RecipeService } from 'src/app/services/recipe/recipe.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-newrecipe',
  templateUrl: './newrecipe.component.html',
  styleUrls: ['./newrecipe.component.scss']
})
export class NewrecipeComponent implements OnInit {

  constructor(
    private recipeService: RecipeService,
    private router: Router
  ) { }

  newRecipe: Object = new Object({
    name: null,
    description: null
  });

  ngOnInit() {
  }

  saveRecipe() {
    this.recipeService.createRecipe(this.newRecipe).pipe().subscribe(r => {
      this.router.navigate(['/recipe/' + r['recipeID'] + '/edit']);
    });
  }

}
