import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { MeasurementUnitService } from 'src/app/services/measurementunit/measurement-unit.service';

@Component({
  selector: 'app-ingredientmodal',
  templateUrl: './ingredientmodal.component.html',
  styleUrls: ['./ingredientmodal.component.scss']
})
export class IngredientmodalComponent implements OnInit {

  constructor(
    public bsModalRef: BsModalRef,
    private measurementUnitService: MeasurementUnitService
  ) { }

  ingredient: Object;
  returnFunc: Function;
  recipeingredient: Object;
  units;

  ngOnInit() {
    this.recipeingredient = {
      ingredientid: this.ingredient['id'],
      amount: "",
      unitid: ""
    };
    this.getUnits();
  }

  getUnits() {
    this.measurementUnitService.getUnits().pipe().subscribe(r => {
      this.units = r;
    });
  }

  updateRecipeIngredient() {
    var returnObj = {
      ingredient: this.ingredient,
      recipeingredient: this.recipeingredient
    }
    this.returnFunc(returnObj);
    this.bsModalRef.hide();
  }
}
