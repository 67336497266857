import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LandingComponent } from './components/landing/landing.component';
import { UserComponent } from './components/user/user.component';
import { GrocerylistComponent } from './components/grocerylist/grocerylist.component';
import { IngredientComponent } from './components/ingredients/ingredient/ingredient.component';
import { IngredientstocklistComponent } from './components/ingredients/ingredientstocklist/ingredientstocklist.component';
import { LoginComponent } from './components/login/login.component';
import { RecipeComponent } from './components/recipes/recipe/recipe.component';
import { RecipelistComponent } from './components/recipes/recipelist/recipelist.component';
import { RecipestepComponent } from './components/recipes/recipestep/recipestep.component';
import { NewrecipeComponent } from './components/recipes/newrecipe/newrecipe.component';
import { EditrecipeComponent } from './components/recipes/editrecipe/editrecipe.component';
import { IngredientlistComponent } from './components/ingredients/ingredientlist/ingredientlist.component';
import { NewingredientComponent } from './components/ingredients/newingredient/newingredient.component';
import { EditingredientComponent } from './components/ingredients/editingredient/editingredient.component';

const routes: Routes = [
  { path: '', component: LandingComponent },
  { path: 'login', component: LoginComponent },
  { path: 'user', component: UserComponent },
  { path: 'grocerylist', component: GrocerylistComponent },
  { path: 'ingredientlist', component: IngredientlistComponent },
  { path: 'ingredient/new', component: NewingredientComponent },
  { path: 'ingredient/:id', component: IngredientComponent },
  { path: 'ingredient/:id/edit', component: EditingredientComponent },
  { path: 'recipelist', component: RecipelistComponent },
  { path: 'recipe/new', component: NewrecipeComponent },
  { path: 'recipe/:id', component: RecipeComponent },
  { path: 'recipe/:id/edit', component: EditrecipeComponent },
  { path: 'recipestep', component: RecipestepComponent },
  { path: 'stock', component: IngredientstocklistComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
