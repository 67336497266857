import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './services/auth/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(authService: AuthService, router: Router) {
    if (!authService.isLoggedIn()) {
      router.navigate(['login']);
    }
  }
  title = 'Ovenmann';
}
